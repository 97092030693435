import {
    Button,
    Cards,
    Input,
    SpaceBetween,
} from '@cloudscape-design/components';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { all } from '../data/solutions';

export const Home: FunctionComponent = () => {
    console.log('Home loaded');
    const navigate = useNavigate();

    const solutions = useMemo(() => all, []);

    const deploy = useCallback(
        (id: string) => {
            navigate(generatePath('/solutions/:id', { id }));
        },
        [navigate]
    );

    const viewDeployments = useCallback(
        (id: string) => {
            navigate(generatePath('/solutions/:id/deployments', { id }));
        },
        [navigate]
    );

    return (
        <SpaceBetween size="l">
            <Input
                value=""
                type="search"
                placeholder="Search all solutions"
                onChange={() => {}}
            />
            <Cards
                items={solutions}
                cardDefinition={{
                    header: (item) => item.name,
                    sections: [
                        {
                            id: 'description',
                            header: 'Description',
                            content: (item) => (
                                <p style={{ maxHeight: 100, minHeight: 100 }}>
                                    {item.description}
                                </p>
                            ),
                        },
                        {
                            id: 'buttons',
                            content: (item) => (
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant="primary"
                                        onClick={() => deploy(item.id)}
                                    >
                                        Deploy 🚀
                                    </Button>
                                    <Button
                                        variant="normal"
                                        onClick={() => viewDeployments(item.id)}
                                    >
                                        View deployments
                                    </Button>
                                    <Button variant="normal">
                                        Source code
                                    </Button>
                                </SpaceBetween>
                            ),
                        },
                    ],
                }}
                cardsPerRow={[{ cards: 3, minWidth: 200 }]}
            />
        </SpaceBetween>
    );
};
