import {
    FunctionComponent,
    ReactNode,
    createContext,
    useEffect,
    useState,
} from 'react';

import { Auth } from 'aws-amplify';
import { ICredentials } from '@aws-amplify/core';
import { useContext } from 'react';

interface User {
    name: string;
    email: string;
}

interface AppContextProps {
    user?: User;
    credentials?: ICredentials;
    config?: Record<string, any>;
}

interface AppContextProviderProps {
    children: ReactNode;
    config?: Record<string, any>;
}

const AppContext = createContext<AppContextProps>({});

export const AppContextProvider: FunctionComponent<AppContextProviderProps> = (
    props
) => {
    const [credentials, setCredentials] = useState<ICredentials | undefined>(
        undefined
    );

    const [user, setUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        if (!credentials) {
            Auth.currentCredentials().then((creds) => setCredentials(creds));
        }

        if (!user) {
            Auth.currentAuthenticatedUser().then((authenticatedUser) => {
                setUser({
                    name: authenticatedUser.username,
                    email: authenticatedUser.attributes.email,
                });
            });
        }
    }, [user, credentials]);

    return (
        <AppContext.Provider
            value={{
                credentials,
                config: props.config,
                user,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);
