import { FunctionComponent, useMemo, useState } from 'react';

import { Geo } from 'aws-amplify';
import { MapView } from '@aws-amplify/ui-react-geo';
import { Marker } from 'react-map-gl';

interface LocationProps {
    location: string;
}

export const Location: FunctionComponent<LocationProps> = (props) => {
    const [coordinates, setCoordinates] = useState<
        | {
              longitude: number;
              latitude: number;
          }
        | undefined
    >();

    useMemo(async () => {
        const places = await Geo.searchByText(props.location);

        setCoordinates({
            longitude: places[0].geometry?.point[0]!,
            latitude: places[0].geometry?.point[1]!,
        });
    }, [props.location]);

    return coordinates ? (
        <MapView
            initialViewState={{
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
                zoom: 1.5,
            }}
            style={{
                maxHeight: 350,
                maxWidth: '100%',
            }}
        >
            <Marker
                latitude={coordinates?.latitude}
                longitude={coordinates?.longitude}
            />
        </MapView>
    ) : (
        <></>
    );
};
