import { useEffect, useRef } from 'react';

export function usePolling(callback: Function, delay: number) {
    const savedCallback = useRef<Function>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        if (delay !== undefined) {
            const id = setInterval(tick, delay);

            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
}
