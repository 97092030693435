import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppLayout from '@aws-northstar/ui/components/AppLayout';
import { Auth } from 'aws-amplify';
import { Configure } from '../pages/Configure';
import { Deploy } from '../pages/Deploy';
import { FunctionComponent } from 'react';
import { Home } from '../pages/Home';
import { ListDeployments } from '../pages/ListDeployments';
import { awsLogo } from '../Logo';
import { useAppContext } from './AppContext';

export const Layout: FunctionComponent = () => {
    const context = useAppContext();

    return (
        <BrowserRouter>
            <AppLayout
                logo={awsLogo}
                title="Web Installer"
                navigationItems={[]}
                user={{
                    email: context.user?.email,
                    username: context.user?.name ?? 'guest',
                }}
                onSignout={() => Auth.signOut()}
                navigationOpen={false}
            >
                <Routes>
                    <Route path="/" element={<Home />} key={'/'} />
                    <Route
                        path="/solutions"
                        element={<Home />}
                        key={'/solutions'}
                    />
                    <Route
                        path="/solutions/:id"
                        element={<Configure />}
                        key="/solutions/:id"
                    />
                    <Route
                        path="/solutions/:id/deployments/:deploymentId"
                        element={<Configure />}
                        key="/solutions/:id/deployments/:deploymentId"
                    />
                    <Route
                        path="/solutions/:id/deployments/:deploymentId/progress"
                        element={<Deploy />}
                        key={
                            '/solutions/:id/deployments/:deploymentId/progress'
                        }
                    />
                    <Route
                        path="solutions/:id/deployments"
                        element={<ListDeployments />}
                        key={'/solutions/:id/deployments'}
                    />
                </Routes>
            </AppLayout>
        </BrowserRouter>
    );
};
