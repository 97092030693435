import * as acm from '@aws-sdk/client-acm';

import {
    ColumnLayout,
    ExpandableSection,
    SpaceBetween,
} from '@cloudscape-design/components';
import { FunctionComponent, useEffect, useMemo } from 'react';

import { ValueWithLabel } from '../ValueWithLabel';
import { useAppContext } from '../AppContext';

export const DeployReview: FunctionComponent<any> = (props) => {
    const context = useAppContext();

    const sdkClientConfig = useMemo(() => {
        return {
            credentials: context.credentials,
            region: 'us-east-1',
        };
    }, [context]);

    const acmClient = useMemo(() => {
        return new acm.ACMClient({ ...sdkClientConfig });
    }, [sdkClientConfig]);

    const cost = useMemo(() => {
        switch (props.data.traffic) {
            case 'small':
                return '$21.5 per month';
            case 'medium':
                return '$38 per month';
            case 'large':
            default:
                return '$58 per month';
        }
    }, [props.data]);

    const hasData = useMemo(
        () => Object.keys(props.data).length > 0,
        [props.data]
    );

    useEffect(() => {
        // request a TLS certificate if the solution needs one
        // we look at whether a domain name is provided to make this decision

        if (props.data.domain && !props.data.review) {
            console.debug({ props });
            acmClient
                .send(
                    new acm.RequestCertificateCommand({
                        DomainName: props.data.domain,
                        ValidationMethod: acm.ValidationMethod.DNS,
                    })
                )
                .then((response) => {
                    console.log(response);
                    props.input.onChange(response.CertificateArn);
                });
        }
    }, [props.data, acmClient]);

    return hasData ? (
        <SpaceBetween size="l">
            <ExpandableSection
                headerText="Hosting configuration"
                expanded={true}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="xxxs">
                        <ValueWithLabel label="Domain name">
                            <p>{props.data.domain}</p>
                        </ValueWithLabel>
                        <ValueWithLabel label="Expected traffic">
                            <p>{props.data.traffic}</p>
                        </ValueWithLabel>
                    </SpaceBetween>
                    <ValueWithLabel label="Deployment region">
                        <p>{props.data.region.label}</p>
                    </ValueWithLabel>
                </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection headerText="Email configuration" expanded={true}>
                <ColumnLayout columns={3} variant="text-grid">
                    <ValueWithLabel label="Admin email address">
                        <p>{props.data.email}</p>
                    </ValueWithLabel>
                    <ValueWithLabel label="SMTP server">
                        <p>{props.data.host}</p>
                    </ValueWithLabel>
                    <ValueWithLabel label="SMTP port">
                        <p>{props.data.port}</p>
                    </ValueWithLabel>
                </ColumnLayout>
                <ColumnLayout columns={3} variant="text-grid">
                    <ValueWithLabel label="SMTP username">
                        <p>{props.data.username}</p>
                    </ValueWithLabel>
                    <ValueWithLabel label="SMTP password">
                        <p>{props.data.password}</p>
                    </ValueWithLabel>
                    <ValueWithLabel label="SMTP over TLS/SSL">
                        <p>{props.data.secure ? 'on' : 'off'}</p>
                    </ValueWithLabel>
                </ColumnLayout>
            </ExpandableSection>
            <ExpandableSection headerText="Estimated cost" expanded={true}>
                <h1>{cost}</h1>
            </ExpandableSection>
        </SpaceBetween>
    ) : (
        <></>
    );
};
