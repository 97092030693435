import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Carousel } from 'react-responsive-carousel';
import { FunctionComponent } from 'react';
import { TextContent } from '@cloudscape-design/components';

interface SolutionsProps {
    solutions: any[];
}
export const Solutions: FunctionComponent<SolutionsProps> = (props) => {
    return (
        <Carousel
            showArrows={true}
            autoPlay={true}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={true}
            showThumbs={false}
            interval={10000}
        >
            {props.solutions.map((x) => (
                <TextContent>
                    <h4>
                        <a href={x.link} target="_blank">
                            {x.title}
                        </a>
                    </h4>
                    <h5>by {x.author}</h5>
                    <p style={{ maxHeight: 150, minHeight: 150 }}>
                        {x.description}
                    </p>
                </TextContent>
            ))}
        </Carousel>
    );
};
