import { FunctionComponent } from 'react';
import ReactJson from 'react-json-view';

interface DeploymentConfigurationProps {
    data: any;
}

export const DeploymentConfiguration: FunctionComponent<
    DeploymentConfigurationProps
> = (props) => {
    return (
        <>
            <ReactJson src={props.data} />
        </>
    );
};
