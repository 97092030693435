import {
    Cards,
    ExpandableSection,
    TextContent,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import useFieldApi from '@data-driven-forms/react-form-renderer/use-field-api';

interface CardProps<T> {
    items: T[];
    label: string;
    name: string;
    description?: string;
}

export const Card = <T extends { id: string; name: any }>(
    props: CardProps<T>
) => {
    const formProps = useFieldApi(props);
    const [initiated, setInitiated] = useState(false);
    const [selectedItems, setSelectedItems] = useState<T[]>(
        formProps.input.value
            ? props.items.filter((x) => x.id === formProps.input.value)
            : []
    );

    useEffect(() => {
        if (!initiated && formProps.input.value) {
            setInitiated(true);
            setSelectedItems(
                props.items.filter((x) => x.id === formProps.input.value)
            );
        }
    }, [formProps.input]);

    return (
        <>
            <input
                id={formProps.name}
                {...formProps.input}
                {...formProps.rest}
                type="hidden"
            />

            <label style={{ fontWeight: 700 }}>{props.label}</label>
            <div
                style={{
                    marginBottom: 10,
                    fontWeight: 400,
                    color: '#5f6b7a',
                    fontSize: 12,
                }}
            >
                {props.description}
            </div>

            <Cards
                items={props.items}
                cardDefinition={{
                    header: (item) => item.name,
                    sections: [
                        {
                            id: 'description',
                            header: 'Description',
                            content: (item) => (item as any).description,
                        },
                        {
                            id: 'price',
                            content: (item) => (
                                <TextContent>
                                    <h1>{`$${(item as any).price} /month`}</h1>
                                </TextContent>
                            ),
                        },
                        {
                            id: 'additional',
                            content: (item) => {
                                const content = item as any;
                                return (
                                    <ExpandableSection
                                        headerText={
                                            'Click to view additional details'
                                        }
                                    >
                                        <TextContent>
                                            <ul>
                                                {' '}
                                                {Object.keys(
                                                    content.additionalDetails
                                                ).map((x) => (
                                                    <li>
                                                        <strong>{x}</strong> -{' '}
                                                        {
                                                            content
                                                                .additionalDetails[
                                                                x
                                                            ]
                                                        }
                                                    </li>
                                                ))}
                                            </ul>
                                        </TextContent>
                                    </ExpandableSection>
                                );
                            },
                        },
                    ],
                }}
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                    formProps.input.onChange(detail.selectedItems[0].id);
                }}
                selectedItems={selectedItems}
                selectionType="single"
            />
        </>
    );
};
