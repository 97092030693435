import { Solution } from '../types';

export const all: Solution[] = [
    {
        id: '1',
        name: 'Simple e-Commerce on AWS',
        description:
            'Deploy this solution to quickly setup a full-featured e-commerce webiste on the AWS cloud.',
    },
    {
        id: '2',
        name: 'Automated Data Analytics on AWS',
        description:
            'Deploy this solution to ingest, transform, query, and manage datasets from multiple sources. Quickly gain insights from data without deep technical experience using Amazon Web Services (AWS).',
    },
    {
        id: '3',
        name: 'Application Pattern Orchestrator on AWS',
        description:
            'Solution that helps customers to establish and manage an internal catalog of reusable, repeatable, well-architected, secure-by-design, and production-ready cloud infrastructure patterns for use by application development and engineering teams throughout their organizations.',
    },
    {
        id: '4',
        name: 'Verifiable Controls Evidence Store',
        description:
            'Deploy a mechanism to centrally store the findings and results of cloud security controls as enduring evidence records.',
    },
    {
        id: '5',
        name: 'Automated Forensics Orchestrator for Amazon EC2',
        description:
            'Deploy the Automated Forensics Orchestrator for Amazon EC2 solution to capture and examine data from EC2 instances and attached volumes for forensic analysis in the event of a potential security issue being detected.',
    },
    {
        id: '6',
        name: 'Dynamic Object and Rule Extensions for AWS Network Firewall',
        description:
            'Solution to specify elastic and dynamic cloud resources as objects that can be easily referenced within AWS Network Firewall rules.',
    },
];
