// ready to deploy bigger
// grey out deploy solution button
// messaging to tell customers they can leave and come back later

import './vertical-progress-bar.css';

import * as acm from '@aws-sdk/client-acm';
import * as iam from '@aws-sdk/client-iam';
import * as lambda from '@aws-sdk/client-lambda';

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { StatusIndicator, TextContent } from '@cloudscape-design/components';

import { API } from 'aws-amplify';
import { useAppContext } from '../AppContext';
import { usePolling } from '../custom-hooks';

type Status = 'success' | 'error' | 'loading';

interface CheckResult {
    status: Status;
    message: string;
}

const iamRoleResult: Record<Status, CheckResult> = {
    loading: { status: 'loading', message: 'Checking your IAM role quota.' },
    success: {
        status: 'success',
        message: 'You have sufficient IAM role quota for the solution.',
    },
    error: {
        status: 'error',
        message: 'You do not have sufficient IAM role quota for the solution.',
    },
};

const cdkBootstrapResult: Record<Status, CheckResult> = {
    loading: { status: 'loading', message: 'Checking your account status.' },
    success: {
        status: 'success',
        message: 'Your account has been CDK bootstrapped.',
    },
    error: {
        status: 'error',
        message: 'Your account has not been CDK bootstrapped.',
    },
};

const smtpResult: Record<Status, CheckResult> = {
    loading: {
        status: 'loading',
        message: 'Checking the provided SMTP settings.',
    },
    success: {
        status: 'success',
        message: 'Successfully validated the provided SMTP settings.',
    },
    error: {
        status: 'error',
        message: `Unable to validate the provided SMTP settings, please review and ensure you've configured the correct values. `,
    },
};

const dnsResult: Record<Status, CheckResult> = {
    success: { status: 'success', message: 'Your domain has been validated.' },
    error: { status: 'error', message: '' },
    loading: {
        status: 'loading',
        message: 'Checking your web domain ownership.',
    },
};

export const EligibilityCheck: FunctionComponent<any> = (props) => {
    const context = useAppContext();
    const sdkClientConfig = useMemo(() => {
        return {
            credentials: context.credentials,
            region: context.config?.aws_project_region,
        };
    }, [context]);

    const [accountBootstrapped, setAccountBootStrapped] = useState<CheckResult>(
        cdkBootstrapResult.loading
    );
    const [iamRolesValid, setIamRolesValid] = useState<CheckResult>(
        iamRoleResult.loading
    );
    const [smtpSettingsValid, setSmtpSettingsValid] = useState<CheckResult>(
        smtpResult.loading
    );
    const [dnsSettingsValid, setDnsSettingsValid] = useState<CheckResult>(
        dnsResult.loading
    );

    const iamClient = useMemo(() => {
        return new iam.IAMClient({ ...sdkClientConfig });
    }, [sdkClientConfig]);

    const lambdaClient = useMemo(() => {
        return new lambda.LambdaClient({ ...sdkClientConfig });
    }, [sdkClientConfig]);

    const acmClient = useMemo(() => {
        return new acm.ACMClient({ ...sdkClientConfig, region: 'us-east-1' });
    }, [sdkClientConfig]);

    useEffect(() => {
        if (!iamClient || !lambdaClient) {
            return;
        }

        API.get("api", "/", {}).then((response) => {
            console.log(response);
        });

        iamClient
            .send(new iam.ListRolesCommand({ MaxItems: 1000 }))
            .then((data) => {
                if (data.Roles) {
                    setIamRolesValid(
                        data.Roles.length < 900
                            ? iamRoleResult.success
                            : iamRoleResult.error
                    );

                    setAccountBootStrapped(
                        data.Roles.filter(
                            (x) =>
                                x.RoleName?.startsWith('cdk-hnb659fds') &&
                                x.RoleName.endsWith(props.data.region.value)
                        ).length > 0
                            ? cdkBootstrapResult.success
                            : cdkBootstrapResult.error
                    );
                }
            });

        const formValues = props.data;

        const smtpSettings = {
            host: formValues.host,
            port: formValues.port,
            secure: formValues.secure,
            auth: {
                user: formValues.username,
                pass: formValues.password,
            },
        };

        lambdaClient
            .send(
                new lambda.InvokeCommand({
                    FunctionName:
                        'web-installer-stack-backend41621279-3K9mGcx75sQG',
                    Payload: JSON.stringify({ body: smtpSettings }),
                })
            )
            .then((response) => {
                setSmtpSettingsValid(
                    response.FunctionError
                        ? smtpResult.error
                        : smtpResult.success
                );
            })
            .catch((_) => setSmtpSettingsValid(smtpResult.error));
    }, [iamClient, lambdaClient, props]);

    // check dns settings separately
    usePolling(() => {
        const formValues = props.data;

        acmClient
            .send(
                new acm.DescribeCertificateCommand({
                    CertificateArn: formValues.review,
                })
            )
            .then((response) => {
                if (response.Certificate?.Status === 'ISSUED') {
                    setDnsSettingsValid(dnsResult.success);
                } else {
                    setDnsSettingsValid({
                        status: 'loading',
                        message: `Validation in progress, please ensure the record with name ${response.Certificate?.DomainValidationOptions?.[0].ResourceRecord?.Name} and value ${response.Certificate?.DomainValidationOptions?.[0].ResourceRecord?.Value} is properly set in your DNS config.`,
                    });
                }
            });
    }, 5000);

    useMemo(() => {
        if (
            accountBootstrapped.status === 'success' &&
            iamRolesValid.status === 'success' &&
            smtpSettingsValid.status === 'success'
        ) {
            props.input.onChange(true);
        } else {
            props.input.onChange(undefined);
        }
    }, [accountBootstrapped, iamRolesValid, props]);

    return iamClient && lambdaClient && acmClient ? (
        <TextContent>
            <div className="timeline-wrapper">
                <div className="node">
                    <h3>CDK Bootstrapped</h3>
                    <p>
                        <StatusIndicator type={accountBootstrapped.status}>
                            {accountBootstrapped.message}
                        </StatusIndicator>
                    </p>
                </div>
                <div className="node">
                    <h3>IAM Roles</h3>
                    <p>
                        <StatusIndicator type={iamRolesValid.status}>
                            {iamRolesValid.message}
                        </StatusIndicator>
                    </p>
                </div>
                <div className="node">
                    <h3>SMTP Settings</h3>
                    <p>
                        <StatusIndicator type={smtpSettingsValid.status}>
                            {smtpSettingsValid.message}
                        </StatusIndicator>
                    </p>
                </div>
                <div className="node">
                    <h3>Domain Ownership</h3>
                    <p>
                        <StatusIndicator type={dnsSettingsValid.status}>
                            {dnsSettingsValid.message}
                        </StatusIndicator>
                    </p>
                </div>
                <div className="node">
                    <h3>Ready to deploy</h3>
                    {props.meta.valid ? (
                        <StatusIndicator type="success">
                            Click <strong>Deploy Solution</strong> to deploy
                            your solution.
                        </StatusIndicator>
                    ) : (
                        <StatusIndicator type="error">
                            Your account is not elligible to host the solution.
                        </StatusIndicator>
                    )}
                </div>
            </div>
        </TextContent>
    ) : (
        <></>
    );
};
