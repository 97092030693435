import * as s3 from '@aws-sdk/client-s3';

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import Table, { ColumnDefinition } from '@aws-northstar/ui/components/Table';

import { Deployment } from '../types';
import { StateRepository } from '../stateRepository';
import { useAppContext } from '../components/AppContext';
import { useParams } from 'react-router-dom';

export const ListDeployments: FunctionComponent = () => {
    const { id } = useParams();
    const [deployments, setDeployments] = useState<Deployment[]>([]);

    const context = useAppContext();

    const stateRepository = useMemo(() => {
        if (context.credentials && context.config) {
            return new StateRepository(
                new s3.S3Client({
                    credentials: context.credentials,
                    region: context.config?.aws_project_region,
                }),
                'web-installer-stack-statebucket5d7007dc-rp1d2uheq1m'
            );
        }
    }, [context, context.credentials, context.config]);

    useEffect(() => {
        stateRepository
            ?.getAllDeployments(id)
            .then((data) => setDeployments(data));
    }, [id, stateRepository]);

    const tableSchema = useMemo<ColumnDefinition<Deployment>[]>(() => {
        return [
            {
                id: 'id',
                width: 200,
                header: 'Id',
                cell: (data) => data.deploymentId,
            },
            {
                id: 'status',
                width: 200,
                header: 'Status',
                cell: (data) => data.status,
            },
            {
                id: 'createdTimestamp',
                width: 300,
                header: 'Created date time',
                cell: (data) =>
                    new Date(data.createdTimestamp).toLocaleString(undefined, {
                        dateStyle: 'medium',
                        timeStyle: 'short',
                    }),
                sortingField: 'createdTimestamp',
            },
        ];
    }, []);

    return stateRepository ? (
        <Table
            items={deployments}
            columnDefinitions={tableSchema}
            disableRowSelect={true}
        />
    ) : (
        <></>
    );
};
