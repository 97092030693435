export const rss = [
    {
        link: 'https://aws.amazon.com/about-aws/whats-new/2023/06/amazon-rds-t4g-database-instances-additional-aws-regions/',
        title: 'Amazon RDS now supports T4g database instances in 3 additional AWS regions',
        description:
            'Amazon Relational Database Service (Amazon RDS) for PostgreSQL, MySQL, and MariaDB now supports AWS Graviton2-based T4g database instances in Asia Pacific (Hyderabad), Europe (Spain), and Middle East (UAE) Regions. T4g database instances provide a baseline level of CPU performance, with the ability to burst CPU usage at any time for as long as required. Depending on the database engine, version, and workload, T4g database instances provide up to 36% better price performance over comparable x86-based T3 database instances.',
        pubDate: 'Wed, 14 Jun 2023 19:19:16 +0000',
        author: 'aws@amazon.com',
    },
    {
        link: 'https://aws.amazon.com/about-aws/whats-new/2023/06/aws-security-hub-new-security-controls/',
        title: 'AWS Security Hub launches 6 new security controls',
        description:
            'AWS Security Hub has released 6 new security controls, increasing the overall number of controls Security Hub offers to 264. The new controls conduct fully-automatic security checks against services such as Amazon CloudFront and Amazon Simple Storage Service (Amazon S3). To use these controls, you should first turn on the standard they belong to - either Foundational Security Best Practices (FSBP) or National Institute of Standards and Technology (NIST) SP 800-53 Rev. 5. If you are already using those standards and have Security Hub set to automatically turn on new controls, these new controls will run without having to take any additional action.',
        pubDate: 'Wed, 14 Jun 2023 17:39:16 +0000',
        author: 'aws@amazon.com',
    },
    {
        link: 'https://aws.amazon.com/about-aws/whats-new/2023/06/amazon-verified-permissions-generally-available/',
        title: 'Amazon Verified Permissions is now generally available',
        description:
            'Today, AWS is announcing the general availability of Amazon Verified Permissions, service for fine-grained authorization and permissions management & for applications that you build. Verified Permissions uses Cedar, an open-source language for access control, allowing you to define permissions as easy-to-understand policies. Use Verified Permissions to support role - and attribute-based access control in your applications.',
        pubDate: 'Tue, 13 Jun 2023 16:32:42 +0000',
        author: 'aws@amazon.com',
    },
];
