import * as s3 from '@aws-sdk/client-s3';

import {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { Deployment } from '../types';
import { InstallerWizard } from '../components/InstallerWizard';
import { StateRepository } from '../stateRepository';
import { getDeploymentSpec } from '../data/solutionClient';
import { useAppContext } from '../components/AppContext';
import { v4 as uuid } from 'uuid';

export const Configure: FunctionComponent = () => {
    const context = useAppContext();

    const navigate = useNavigate();
    const { id, deploymentId } = useParams();
    const [currentDeployment, setCurrentDeployment] = useState<
        Deployment | undefined
    >(undefined);

    const stateRepository = useMemo(() => {
        if (context.credentials && context.config) {
            return new StateRepository(
                new s3.S3Client({
                    credentials: context.credentials,
                    region: context.config?.aws_project_region,
                }),
                'web-installer-stack-statebucket5d7007dc-rp1d2uheq1m'
            );
        }
    }, [context]);

    useEffect(() => {
        if (id && !deploymentId) {
            // this is a brand new deployment
            getDeploymentSpec(id).then((spec) => {
                const deployment: Deployment = {
                    solutionId: id,
                    deploymentId: uuid(),
                    configuration: spec.wizard.initialValues,
                    createdTimestamp: new Date(),
                    deploySpec: spec,
                    status: 'Not started',
                };

                setCurrentDeployment(deployment);
            });
        } else if (id && deploymentId) {
            // this is an existing deployment
            stateRepository?.getDeployment(id, deploymentId).then((d) => {
                if (d) {
                    setCurrentDeployment(d);
                }
            });
        }
    }, [id, deploymentId, stateRepository]);

    const saveState = useCallback(
        async (formValues: any) => {
            await stateRepository?.updateDeploymentConfig({
                ...currentDeployment!,
                configuration: formValues,
                deploySpec: {
                    ...currentDeployment!.deploySpec,
                    wizard: {
                        ...currentDeployment!.deploySpec.wizard,
                        initialValues: formValues,
                    },
                },
            });
        },
        [stateRepository, currentDeployment]
    );

    const startDeployment = useCallback(
        (formValues: any) => {
            stateRepository?.startDeployment(currentDeployment!).then((_) => {
                navigate(
                    generatePath(
                        '/solutions/:id/deployments/:deploymentId/progress',
                        {
                            id: id!,
                            deploymentId: currentDeployment?.deploymentId!,
                        }
                    ),
                    {
                        state: {
                            deployProps: formValues,
                            solutionName: 'Simple e-Commerce on AWS',
                        },
                    }
                );
            });
        },
        [id, navigate, currentDeployment, stateRepository]
    );

    return stateRepository ? (
        <InstallerWizard
            onSubmit={startDeployment}
            wizardSchema={currentDeployment?.deploySpec.wizard.stepSchema ?? []}
            initialValues={
                currentDeployment?.deploySpec.wizard.initialValues ?? {}
            }
            onNavigating={saveState}
        />
    ) : (
        <></>
    );
};
