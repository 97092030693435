import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';

import { FunctionComponent, useEffect, useState } from 'react';

import CodeEditor from '@cloudscape-design/components/code-editor';

interface LogViewerProps {
    content: string;
    isLoading: boolean;
}

const i18nStrings = {
    loadingState: 'Loading deployment logs',
    errorState: 'There was an error loading the code editor.',
    errorStateRecovery: 'Retry',

    editorGroupAriaLabel: 'Code editor',
    statusBarGroupAriaLabel: 'Status bar',

    cursorPosition: (row: number, column: number) => `Ln ${row}, Col ${column}`,
    errorsTab: 'Errors',
    warningsTab: 'Warnings',
    preferencesButtonAriaLabel: 'Preferences',

    paneCloseButtonAriaLabel: 'Close',

    preferencesModalHeader: 'Preferences',
    preferencesModalCancel: 'Cancel',
    preferencesModalConfirm: 'Confirm',
    preferencesModalWrapLines: 'Wrap lines',
    preferencesModalTheme: 'Theme',
    preferencesModalLightThemes: 'Light themes',
    preferencesModalDarkThemes: 'Dark themes',
};

export const LogViewer: FunctionComponent<LogViewerProps> = (props) => {
    const [preferences, setPreferences] = useState({});
    const [ace, setAce] = useState<any>();

    useEffect(() => {
        async function loadAce() {
            const ace = await import('ace-builds');
            await import('ace-builds/webpack-resolver');
            ace.config.set('useStrictCSP', true);

            return ace;
        }

        loadAce().then((ace) => setAce(ace));
    }, []);
    return (
        <CodeEditor
            ace={ace}
            value={props.content}
            language={'text'}
            i18nStrings={i18nStrings}
            onPreferencesChange={(event) => setPreferences(event.detail)}
            preferences={preferences}
            loading={props.isLoading}
        />
    );
};
