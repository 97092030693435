import { Build, StatusType } from '@aws-sdk/client-codebuild';
import { FunctionComponent, useMemo } from 'react';
import { ProgressBar, StatusIndicator } from '@cloudscape-design/components';

interface DeploymentProgress {
    build?: Build;
}

const buildPhases: Record<string, string> = {
    SUBMITTED: 'Submitting your deployment',
    QUEUED: 'Queuing your deployment',
    PROVISIONING: 'Provisioning resources to execute your deployment',
    DOWNLOAD_SOURCE: `Downloading the solution's source code`,
    INSTALL: 'Installing depenencies required for your build and deployment',
    PRE_BUILD: 'Executing pre build scripts',
    BUILD: 'Building and deploying your solution',
    POST_BUILD: 'Executing post build scripts',
    UPLOAD_ARTIFACTS: 'Uploading output artifacts',
    FINALIZING: 'Finalising your deployment',
    COMPLETED: 'Deployment successfully completed',
};

export const DeploymentProgress: FunctionComponent<DeploymentProgress> = (
    props
) => {
    const value = useMemo(() => {
        if (!props.build) return 0;

        return (
            ((Object.keys(buildPhases).indexOf(props.build.currentPhase!) + 1) /
                Object.keys(buildPhases).length) *
            100
        );
    }, [props.build]);

    return (
        <>
            <ProgressBar
                additionalInfo={<DeploymentStatus build={props.build} />}
                description={
                    props.build?.currentPhase
                        ? buildPhases[props.build.currentPhase]
                        : 'n/a'
                }
                value={value}
                variant="standalone"
            />
        </>
    );
};

interface DeploymentStatusProps {
    build?: Build;
}

export const DeploymentStatus: FunctionComponent<DeploymentStatusProps> = (
    props
) => {
    const type = useMemo(() => {
        if (!props.build) return 'loading';

        switch (props.build.buildStatus) {
            case StatusType.FAILED:
            case StatusType.FAULT:
            case StatusType.TIMED_OUT:
                return 'error';
            case StatusType.IN_PROGRESS:
                return 'loading';
            case StatusType.SUCCEEDED:
                return undefined;
            case StatusType.STOPPED:
                return 'stopped';
            default:
                break;
        }
    }, [props.build]);

    const statusMessage = useMemo(() => {
        if (!props.build) return '';

        return props.build.currentPhase === 'COMPLETED'
            ? 'completed'
            : 'in progress';
    }, [props.build]);

    return (
        <StatusIndicator type={type}>
            <span style={{ textTransform: 'capitalize' }}>{statusMessage}</span>
        </StatusIndicator>
    );
};
