import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Carousel } from 'react-responsive-carousel';
import { FunctionComponent } from 'react';
import { TextContent } from '@cloudscape-design/components';
import { rss } from '../data/rss';

//import Parser from 'rss-parser';

export const RSSFeed: FunctionComponent = () => {
    return (
        <>
            <Carousel
                showArrows={true}
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                showThumbs={false}
                interval={10000}
            >
                {rss.map((x) => (
                    <TextContent>
                        <h4>
                            <a href={x.link} target="_blank">
                                {x.title}
                            </a>
                        </h4>
                        <h5>
                            Posted on {new Date(x.pubDate).toLocaleString()}
                        </h5>
                        <p style={{ maxHeight: 200, minHeight: 150 }}>
                            {x.description}
                        </p>
                    </TextContent>
                ))}
            </Carousel>
        </>
    );
};
