import { FunctionComponent, ReactNode } from 'react';

import { Box } from '@cloudscape-design/components';

interface ValueWithLabelProps {
    label: string;
    children: ReactNode;
}

export const ValueWithLabel: FunctionComponent<ValueWithLabelProps> = (
    props
) => {
    return (
        <div>
            <Box variant="awsui-key-label">{props.label}</Box>
            <div>{props.children}</div>
        </div>
    );
};
