import * as s3 from '@aws-sdk/client-s3';

import { Deployment } from './types';
import { Readable } from 'stream';
import { SdkStream } from '@aws-sdk/types';

export class StateRepository {
    constructor(
        private readonly s3Client: s3.S3Client,
        private readonly bucketName: string
    ) {}

    async getAllDeployments(solutionId?: string): Promise<Deployment[]> {
        const allObjects = await this.s3Client.send(
            new s3.ListObjectsV2Command({
                Bucket: this.bucketName,
                Prefix: solutionId,
            })
        );

        const downloadObjectTasks = allObjects.Contents?.map(async (x) => {
            const data = await this.s3Client.send(
                new s3.GetObjectCommand({ Bucket: this.bucketName, Key: x.Key })
            );
            return this.parseContent(data.Body);
        });

        return (await Promise.all(downloadObjectTasks ?? []))
            .filter((x) => x !== undefined)
            .map((x) => x!);
    }

    async getDeployment(
        solutionId: string,
        deploymentId: string
    ): Promise<Deployment | undefined> {
        const data = await this.s3Client.send(
            new s3.GetObjectCommand({
                Bucket: this.bucketName,
                Key: `${solutionId}/${deploymentId}.json`,
            })
        );
        return this.parseContent(data.Body);
    }

    async updateDeploymentConfig(deployment: Deployment): Promise<void> {
        this.s3Client.send(
            new s3.PutObjectCommand({
                Bucket: this.bucketName,
                Key: `${deployment.solutionId}/${deployment.deploymentId}.json`,
                ContentType: 'application/json',
                Body: JSON.stringify(deployment),
            })
        );
    }

    async startDeployment(deployment: Deployment): Promise<void> {
        this.getDeployment(deployment.solutionId, deployment.deploymentId).then(
            (data) => {
                if (data) {
                    this.updateDeploymentConfig({
                        ...data,
                        status: 'In progress',
                    });
                }
            }
        );
    }

    async setBuildArn(deployment: Deployment, buildArn: string): Promise<void> {
        this.getDeployment(deployment.solutionId, deployment.deploymentId).then(
            (data) => {
                if (data) {
                    this.updateDeploymentConfig({
                        ...data,
                        codeBuildArn: buildArn,
                    });
                }
            }
        );
    }

    async endDeployment(deployment: Deployment): Promise<void> {
        this.getDeployment(deployment.solutionId, deployment.deploymentId).then(
            (data) => {
                if (data) {
                    this.updateDeploymentConfig({
                        ...data,
                        status: 'Succeeded',
                    });
                }
            }
        );
    }

    private async parseContent(
        content:
            | SdkStream<Readable | ReadableStream<any> | Blob | undefined>
            | undefined
    ): Promise<Deployment | undefined> {
        const jsonContent = await content?.transformToString();

        return jsonContent ? JSON.parse(jsonContent) : undefined;
    }
}
