import '@aws-amplify/ui-react/styles.css';

import { Amplify, Auth } from 'aws-amplify';

import { AppContextProvider } from './components/AppContext';
import { Authenticator } from '@aws-amplify/ui-react';
import { Density } from '@cloudscape-design/global-styles';
import { Layout } from './components/Layout';
import { NorthStarThemeProvider } from '@aws-northstar/ui';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

const amplifyConfig = Amplify.configure({
    aws_project_region: 'ap-southeast-2',
    aws_cognito_identity_pool_id:
        'ap-southeast-2:67f01298-f656-4974-995f-c96989b104b8',
    aws_cognito_region: 'ap-southeast-2',
    aws_user_pools_id: 'ap-southeast-2_vwGDuMgQ5',
    aws_user_pools_web_client_id: '5jemg5l82cmfrii8sms5cqsq58',
    aws_cloud_logic_custom: [
        {
            name: 'api',
            endpoint:
                'https://pwsgxody52.execute-api.ap-southeast-2.amazonaws.com/prod/',
            region: 'ap-southeast-2',
        },
    ],
    geo: {
        AmazonLocationService: {
            maps: {
                items: {
                    'web-installer.map': {
                        style: 'VectorEsriStreets',
                    },
                },
                default: 'web-installer.map',
            },
            region: 'ap-southeast-2',
            search_indices: {
                items: ['web-installer-index'],
                default: 'web-installer-index',
            },
        },
    },
});

console.log({amplifyConfig})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <NorthStarThemeProvider density={Density.Compact}>
            <Authenticator hideSignUp={true}>
                <AppContextProvider config={amplifyConfig}>
                    <Layout />
                </AppContextProvider>
            </Authenticator>
        </NorthStarThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
