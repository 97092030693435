import { FormRenderer, validatorTypes } from '@aws-northstar/ui';
import { FunctionComponent, useMemo } from 'react';

import { Card } from './form-renderers/Card';
import { DeployReview } from './form-renderers/DeployReview';
import { EligibilityCheck } from './form-renderers/EligibilityCheck';
import { NavigatingEventDetail } from '@aws-northstar/ui/components/FormRenderer/components/Wizard';
import { NonCancelableCustomEvent } from '@cloudscape-design/components';
import { WizardStep } from '../types';

interface InstallerWizardProps {
    onSubmit: (formValues: Record<string, any>) => void;
    onNavigating: (formValues: Record<string, any>) => Promise<void>;
    wizardSchema: WizardStep[];
    initialValues?: Record<string, any>;
}

export const InstallerWizard: FunctionComponent<InstallerWizardProps> = (
    props
) => {
    const schema = useMemo(() => {
        return {
            description: 'Configure and deploy your solution',
            fields: [
                {
                    allowSkipTo: true,
                    component: 'wizard',
                    i18nStrings: {
                        submitButton: 'Deploy solution',
                    },
                    fields: [
                        ...props.wizardSchema,
                        {
                            fields: [
                                {
                                    Template: DeployReview,
                                    component: 'REVIEW',
                                    name: 'review',
                                },
                            ],
                            name: 'review',
                            title: 'Review',
                        },
                        {
                            fields: [
                                {
                                    Template: EligibilityCheck,
                                    component: 'REVIEW',
                                    name: 'config4',
                                    validate: [
                                        {
                                            type: validatorTypes.REQUIRED,
                                        },
                                    ],
                                },
                            ],
                            name: 'validate',
                            title: 'Check account eligibility',
                        },
                    ],
                    name: 'wizard',
                    onNavigating: async (
                        event: NonCancelableCustomEvent<NavigatingEventDetail>
                    ) => {
                        await props.onNavigating(event.detail.values);
                        return { continued: true };
                    },
                },
            ],
            header: 'Simple e-Commerce on AWS',
        };
    }, [props.wizardSchema]);

    const initValue = useMemo(() => props.initialValues, [props.initialValues]);

    return initValue ? (
        <FormRenderer
            onSubmit={(formValues) => {
                props.onSubmit(formValues);
            }}
            schema={schema}
            initialValues={initValue}
            customComponentWrapper={{
                Cards: Card,
            }}
        ></FormRenderer>
    ) : (
        <></>
    );
};
