// Configuration page
// - everything must have defaults
// - 3 and less items => cards/radios, prefer cards as they're more visual
// - more than 3 dropdown
// - have a cost attached to each configuration

// Deployment
// - progress right at the top, everything expandable, buyer persona don't care
// - pull new articles from "what's new on aws"
// - maybe show a map and put a pin on the deployment region

import * as cb from '@aws-sdk/client-codebuild';
import * as logs from '@aws-sdk/client-cloudwatch-logs';
import * as ssm from '@aws-sdk/client-ssm';

import {
    Button,
    ColumnLayout,
    Container,
    ExpandableSection,
    Header,
    SpaceBetween,
} from '@cloudscape-design/components';
import {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { deploySpec, relatedSolutions } from '../data/eCommerce';

import { DeploymentConfiguration } from '../components/DeploymentConfiguration';
import { DeploymentProgress } from '../components/DeploymentProgress';
import { Location } from '../components/Location';
import { LogViewer } from '../components/LogViewer';
import { RSSFeed } from '../components/RSSFeed';
import { Solutions } from '../components/OtherSolutions';
import { ValueWithLabel } from '../components/ValueWithLabel';
import { useAppContext } from '../components/AppContext';
import { useLocation } from 'react-router-dom';
import { usePolling } from '../components/custom-hooks';

export const Deploy: FunctionComponent = () => {
    const location = useLocation();

    const context = useAppContext();

    const deployProps = useMemo(
        () => location.state.deployProps,
        [location.state.deployProps]
    );

    const sdkClientConfig = useMemo(() => {
        return {
            credentials: context.credentials,
            region: context.config?.aws_project_region,
        };
    }, [context]);

    const codeBuildClient = useMemo(() => {
        return new cb.CodeBuildClient({ ...sdkClientConfig });
    }, [sdkClientConfig]);

    const ssmClient = useMemo(() => {
        return new ssm.SSMClient({ ...sdkClientConfig });
    }, [sdkClientConfig]);

    const logsClient = useMemo(() => {
        return new logs.CloudWatchLogsClient({ ...sdkClientConfig });
    }, [sdkClientConfig]);

    const [currentBuild, setCurrentBuild] = useState<cb.Build | undefined>();

    const [logGroup, setLogGroup] = useState<
        { logGroupName: string; logStreamName: string } | undefined
    >(undefined);

    const [logNextToken, setLogNextToken] = useState<string | undefined>(
        undefined
    );

    const [logOutput, setLogOutput] = useState<string[]>([]);

    useEffect(() => {
        async function startBuild() {
            const projectName = await ssmClient.send(
                new ssm.GetParameterCommand({
                    Name: '/solutions/web-installer/build-project-name',
                })
            );
            const startBuild = await codeBuildClient.send(
                new cb.StartBuildCommand({
                    projectName: projectName.Parameter?.Value!,
                    imageOverride: deploySpec.image,
                    buildspecOverride: deploySpec.buildSpec,
                    environmentVariablesOverride: [
                        {
                            name: 'AWS_REGION',
                            value: deployProps.region.value,
                        },
                    ],
                })
            );
            if (startBuild.build) {
                setCurrentBuild(startBuild.build);
            }
        }
        if (codeBuildClient && ssmClient && deployProps) {
            startBuild();
        }
    }, [codeBuildClient, ssmClient, location, deployProps]);

    usePolling(async () => {
        if (
            currentBuild === undefined ||
            currentBuild.buildStatus === 'SUCCEEDED'
        )
            return;

        const buildStatus = await codeBuildClient.send(
            new cb.BatchGetBuildsCommand({ ids: [currentBuild.arn!] })
        );
        setCurrentBuild(buildStatus.builds?.[0]);
        setLogGroup({
            logGroupName: buildStatus.builds?.[0].logs?.groupName!,
            logStreamName: buildStatus.builds?.[0].logs?.streamName!,
        });
    }, 5000);

    usePolling(async () => {
        if (
            logGroup?.logGroupName === undefined ||
            logGroup?.logGroupName === null ||
            currentBuild?.buildStatus === 'SUCCEEDED'
        )
            return;

        const getLogs = await logsClient.send(
            new logs.GetLogEventsCommand({
                logStreamName: logGroup.logStreamName,
                logGroupName: logGroup.logGroupName,
                nextToken: logNextToken,
            })
        );

        if (getLogs.events)
            setLogOutput(
                logOutput.concat(getLogs.events.map((x) => x.message!))
            );

        if (getLogs.events!.length > 0) {
            setLogNextToken(getLogs.nextForwardToken);
        }
    }, 5000);

    const sendLog = useCallback(() => {
        console.log({ logOutput });
        window.location.href = `mailto:aws-solutions@amazon.com?body=deployment configuration=${JSON.stringify(
            deployProps
        )} %0D %0D deployment log=${logOutput.join('%0D')}`;
    }, [deployProps, logOutput]);

    return (
        <SpaceBetween size="l">
            <Container
                header={
                    <Header variant="h2">
                        {location.state?.solutionName} deployment
                    </Header>
                }
            >
                <ExpandableSection headerText="Progress" defaultExpanded={true}>
                    <DeploymentProgress build={currentBuild} />
                </ExpandableSection>
            </Container>
            <Container>
                <ColumnLayout columns={2} variant="text-grid">
                    <ExpandableSection
                        headerText="Frequently deployed together"
                        defaultExpanded={true}
                    >
                        <Solutions solutions={relatedSolutions} />
                    </ExpandableSection>

                    <ExpandableSection
                        headerText="What's new on AWS"
                        defaultExpanded={true}
                    >
                        <RSSFeed />
                    </ExpandableSection>
                </ColumnLayout>
            </Container>
            <Container>
                <ExpandableSection headerText="Deployment details">
                    <ColumnLayout columns={2} variant="text-grid">
                        <ValueWithLabel
                            label={`Deployment region: ${deployProps.region.label} (${deployProps.region.value})`}
                        >
                            <Location location={deployProps.region.label} />
                        </ValueWithLabel>
                        <SpaceBetween size="l">
                            <ExpandableSection
                                headerText="Deployment configuration"
                                defaultExpanded={false}
                            >
                                <DeploymentConfiguration data={deployProps} />
                            </ExpandableSection>
                            <ExpandableSection
                                headerText="Deployment logs"
                                defaultExpanded={false}
                            >
                                <LogViewer
                                    content={logOutput.join('')}
                                    isLoading={logOutput.length === 0}
                                />
                            </ExpandableSection>

                            <Button
                                iconName="envelope"
                                variant="primary"
                                onClick={() => sendLog()}
                            >
                                Help
                            </Button>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
            </Container>
        </SpaceBetween>
    );
};
