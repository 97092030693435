import { DeployReview } from '../components/form-renderers/DeployReview';
import { EligibilityCheck } from '../components/form-renderers/EligibilityCheck';
import { validatorTypes } from '@aws-northstar/ui';

export const schema = {
    description: 'Configure and deploy your solution',
    fields: [
        {
            allowSkipTo: true,
            component: 'wizard',
            i18nStrings: {
                submitButton: 'Deploy solution',
            },
            fields: [
                {
                    fields: [
                        {
                            component: 'text-field',
                            isRequired: true,
                            label: 'Website domain name',
                            description: `Your e-commerce website's top domain name. For example www.amazon.com`,
                            name: 'domain',
                            validate: [
                                {
                                    type: validatorTypes.REQUIRED,
                                },
                            ],
                        },
                        {
                            component: 'select',
                            isRequired: true,
                            name: 'region',
                            label: 'Deployment region',
                            description:
                                'Region where you would like to deploy your website.',
                            options: [
                                {
                                    label: 'Sydney',
                                    value: 'ap-southeast-2',
                                },
                                { label: 'Oregon', value: 'us-west-2' },
                                {
                                    label: 'North Virginia',
                                    value: 'us-east-1',
                                },
                                {
                                    label: 'Tokyo',
                                    value: 'ap-northeast-1',
                                },
                            ],
                            validate: [
                                {
                                    type: validatorTypes.REQUIRED,
                                },
                            ],
                        },
                        {
                            component: 'Cards',
                            label: 'Expected traffic',
                            description: `Your website's expected traffic.`,
                            name: 'traffic',
                            items: [
                                {
                                    id: 'small',
                                    name: 'Small',
                                    description:
                                        'Low traffic website up to 1,000 users.',
                                    price: 21.5,
                                    additionalDetails: {
                                        memory: '512 MB',
                                        cpu: '1 vCPU',
                                        storage: '20 GB SSD',
                                        dataTransfer: '512 GB',
                                    },
                                },
                                {
                                    id: 'medium',
                                    name: 'Medium',
                                    description:
                                        'Medium traffic website up to 5,000 users.',
                                    price: 38,
                                    additionalDetails: {
                                        memory: '4 GB',
                                        cpu: '2 vCPUs',
                                        storage: '80 GB SSD',
                                        dataTransfer: '2 TB',
                                    },
                                },
                                {
                                    id: 'large',
                                    name: 'Large',
                                    description:
                                        'High traffic website over 5,000 users.',
                                    price: 58,
                                    additionalDetails: {
                                        memory: '8 GB',
                                        cpu: '2 vCPUs',
                                        storage: '160 GB SSD',
                                        dataTransfer: '2.5 TB',
                                    },
                                },
                            ],
                        },
                    ],
                    name: 'step-1',
                    title: 'Configure hosting',
                },
                {
                    fields: [
                        {
                            component: 'switch',
                            isRequired: true,
                            label: 'Retain data',
                            description:
                                'Controls what happens to your data when you delete the website.',
                            name: 'retainData',
                            validate: [
                                {
                                    type: validatorTypes.REQUIRED,
                                },
                            ],
                        },
                        {
                            component: 'radio',
                            isRequired: true,
                            label: 'Backup/snapshot rentention policy',
                            description:
                                'Controls how long your backups and shapshots will be kept.',
                            name: 'backupRetain',
                            options: [
                                {
                                    label: '1 Day',
                                    value: '1',
                                },
                                {
                                    label: '1 Week',
                                    value: '2',
                                },
                                {
                                    label: '1 Month',
                                    value: '3',
                                },
                                {
                                    label: '1 Year',
                                    value: '4',
                                },
                            ],
                            validate: [
                                {
                                    type: validatorTypes.REQUIRED,
                                },
                            ],
                        },
                    ],
                    name: 'step-2',
                    title: 'Configure your data settings',
                },
                {
                    fields: [
                        {
                            component: 'text-field',
                            isRequired: true,
                            label: 'Admin email address',
                            description:
                                'The initial administrator password will be sent to this email address.',
                            name: 'email',
                            validate: [
                                {
                                    type: validatorTypes.REQUIRED,
                                },
                                {
                                    type: validatorTypes.PATTERN,
                                    pattern:
                                        '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$',
                                    message: 'Not valid email',
                                },
                            ],
                        },
                        {
                            component: 'switch',
                            description:
                                'Enhance your website security by adding or enabling multi-factor authentication.',
                            isRequired: false,
                            label: 'Enable MFA',
                            name: 'mfa',
                        },
                    ],
                    name: 'step-3',
                    title: 'Configure user settings',
                },
                {
                    fields: [
                        {
                            Template: DeployReview,
                            component: 'REVIEW',
                            name: 'review',
                        },
                    ],
                    name: 'review',
                    title: 'Review',
                },
                {
                    fields: [
                        {
                            Template: EligibilityCheck,
                            component: 'REVIEW',
                            name: 'config4',
                            validate: [
                                {
                                    type: validatorTypes.REQUIRED,
                                },
                            ],
                        },
                    ],
                    name: 'validate',
                    title: 'Check account eligibility',
                },
            ],
            name: 'wizard',
        },
    ],
    header: 'Simple e-Commerce on AWS',
};

export const initialValues = {
    traffic: 'small',
    retainData: true,
    region: {
        label: 'Sydney',
        value: 'ap-southeast-2',
    },
};

export const relatedSolutions = [
    {
        link: 'https://aws.amazon.com/solutions/implementations/automated-data-analytics-on-aws/',
        title: 'Automated Data Analytics on AWS',
        description:
            'Automated Data Analytics on AWS enables you to derive meaningful insights from data in a matter of minutes through a simple and intuitive user interface. This solution helps to easily consolidate data distributed across siloes, apply fine-grained governance controls, and query data through a tailored user experience that is abstracted from underlying AWS services. The solution is quickly deployed to an AWS account through a single click, removing the need for deep technical expertise.',
        pubDate: 'Wed, 14 Jun 2023 19:19:16 +0000',
        author: 'aws@amazon.com',
    },
    {
        link: 'https://aws.amazon.com/solutions/implementations/application-pattern-orchestrator-on-aws/',
        title: 'Application Pattern Orchestrator on AWS',
        description:
            'Application Pattern Orchestrator on AWS is a one-click AWS Solution that helps to establish an automated framework to publish, govern, and maintain reusable, well-architected, secure-by-design, and production-ready application patterns for use by engineering teams in your organization. It offers a set of integrated capabilities that facilitates decentralized contribution of application patterns, automated validation of pattern compliance with organizational policies, as well as central, unified discoverability.',
        pubDate: 'Wed, 14 Jun 2023 17:39:16 +0000',
        author: 'aws@amazon.com',
    },
    {
        link: 'https://aws.amazon.com/solutions/implementations/verifiable-controls-evidence-store/',
        title: 'Verifiable Controls Evidence Store',
        description:
            'This solution provides a mechanism to centrally store evidence issued by cloud security controls governing AWS workloads, as enduring evidence records that are safeguarded against tampering. Stored controls evidence can subsequently be utilized in compliance evaluation mechanisms, deployment decisions, or audit processes. An evidence record is a system- or human-generated digital record of a historical fact, related to one or more target entities, and issued by an evidence provider.',
        pubDate: 'Tue, 13 Jun 2023 16:32:42 +0000',
        author: 'aws@amazon.com',
    },
];

export const deploySpec = {
    image: 'aws/codebuild/standard:7.0',
    buildSpec: `
    version: 0.2
    phases:
        install:
            runtime-versions:
                nodejs: 18
            commands:
                - npm install -g cdk
                - npm install -g yarn
                - mkdir test-app
                - cd test-app && cdk init app --language typescript && cd -
        pre_build:
            commands:
                - cd test-app && npm install && cd -
        build:
            commands:
                - cd test-app && npm run cdk synth && npm run cdk deploy -- --require-approval never
    artifacts:
        files:
            - '**/*'

    `,
};
